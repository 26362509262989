import { useState, useEffect } from "react"
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import httpInstance from "./httpClient"
import UserProfile from "./userProfile"
import getCookie from "./getCookie"
import TopMenu from './TopMenu'
import SellOptions from './routes/SellOptions'
import BuyOptions from './routes/BuyOptions'
import Positions from './routes/Positions'
import Volatility from './routes/Volatility'
import Trades from './routes/Trades'
import Login from './routes/Login'


// eslint-disable-next-line
Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

const App = () => {
    const navigate = useNavigate();
    // General variables
    const [serverStatus, setServerStatus] = useState({'value': 0, 'text': 'Down'})
    const [timeState, setTimeState] = useState(0)
    // Account
    const [account, setAccount] = useState({})
    // Orders
    const [orders, setOrders] = useState({})
    // Executions
    const [executions, setExecutions] = useState({})
    // Underlying Selection
    const [underlyings, setUnderlyings] = useState([])
    const [selectedUnderlying, setSelectedUnderlying] = useState({})
    const [optionsExpiries, setOptionsExpiries] = useState([])
    // Undelrying market data
    const [basicMarketdData, setBasicMarketdData] = useState({})
    // Standard Deviation data
    const [standardDeviationData, setStandardDeviationData] = useState({})
    // Sell options variables
    const [putRisk, setPutRisk] = useState(0)
    const [callRisk, setCallRisk] = useState(0)
    const [marginTarget, setMarginTarget] = useState(0)
    const [sellTrades, setSellTrades] = useState([])
    // Buy options variables
    const [atDateList, setAtDateList] = useState([])
    const [changeType, setChangeType] = useState(0)
    const [changePerc, setChangePerc] = useState(0)
    const [selectedAtDate, setSelectedAtDate] = useState({})
    const [risk, setRisk] = useState(0)
    const [buyTrades, setBuyTrades] = useState([])
    // Generic variables
    const [selectedExpiry, setSelectedExpiry] = useState({})
    const [iv, setIV] = useState({})
    const [riskFreeRate, setRiskFreeRate] = useState({})
    const [volatility, setVolatility] = useState(0)
    const [spinnerFindTrade, setspinnerFindTrade] = useState(false)
    const [bookTradeResponse, setBookTradeResponse] = useState({})
    // Position variables
    const [positions, setPositions] = useState({})
    const [updateTradeResponse, setUpdateTradeResponse] = useState({})
    const [closeTradeResponse, setCloseTradeResponse] = useState({})

    const reset = async () => {
        UserProfile.clearUser()
        while (Object.keys(UserProfile.getUser()).length !== 0) {
            console.log('wait')
        }
        setServerStatus({'value': 0, 'text': 'Down'})
        setTimeState(0)
        setAccount({})
        setOrders({})
        setExecutions({})
        setUnderlyings([])
        setSelectedUnderlying({})
        setOptionsExpiries([])
        setBasicMarketdData({})
        setStandardDeviationData({})
        setPutRisk(0)
        setCallRisk(0)
        setMarginTarget(0)
        setSellTrades([])
        setAtDateList([])
        setChangeType(0)
        setChangePerc(0)
        setSelectedAtDate({})
        setRisk(0)
        setBuyTrades([])
        setSelectedExpiry({})
        setIV({})
        setRiskFreeRate({})
        setVolatility(0)
        setspinnerFindTrade(false)
        setBookTradeResponse({})
        setPositions({})
        setUpdateTradeResponse({})
        setCloseTradeResponse({})
    }

    useEffect(() => {
        const interval = setInterval(() => {

            if(Object.keys(UserProfile.getUser()).length === 0){
                return
            }

            httpInstance.get('/getStatus')
            .then(function (response) {
                setServerStatus(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                console.log("Server is Down : " + error);
                console.log(error);
            });
            
            
            if (serverStatus.value === 1 || serverStatus.value === 2)
            {
                httpInstance.get('/getAccount')
                .then(function (response) {
                    setAccount(response.data);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 401) {
                            console.log('User Logged Out')
                            reset()
                        }
                    }
                    console.log(error);
                });

                httpInstance.get('/getPositions')
                .then(function (response) {
                    setPositions(response.data);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 401) {
                            console.log('User Logged Out')
                            reset()
                        }
                    }
                    console.log(error);
                });

                httpInstance.get('/getOrders')
                .then(function (response) {
                    setOrders(response.data);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 401) {
                            console.log('User Logged Out')
                            reset()
                        }
                    }
                    console.log(error);
                });

                httpInstance.get('/getExecutions')
                .then(function (response) {
                    setExecutions(response.data);
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 401) {
                            console.log('User Logged Out')
                            reset()
                        }
                    }
                    console.log(error);
                });
            }
            
            if (serverStatus.value === 2)
            {
                if (Object.keys(selectedUnderlying).length !== 0) {
                    httpInstance.post('/getBasicMarketData', {
                        'symbol': selectedUnderlying.symbol,
                        'secType': selectedUnderlying.secType
                    },{
                        headers: {
                            'X-CSRF-TOKEN': getCookie('csrf_access_token')
                        }
                    })
                    .then(function (response) {
                        setBasicMarketdData(response.data);
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 401) {
                                console.log('User Logged Out')
                                reset()
                            }
                        }
                        console.log(error);
                    });
                };
    
                if (Object.keys(basicMarketdData).length !== 0) {
                    setTimeState(new Date().addHours(basicMarketdData.timeDifference));
                };
    
                if (Object.keys(selectedExpiry).length !== 0) {
                    httpInstance.post('/getExpiryIV', {
                        'symbol': selectedUnderlying.symbol,
                        'secType': selectedUnderlying.secType,
                        'expiry': selectedExpiry.name,
                    },{
                        headers: {
                            'X-CSRF-TOKEN': getCookie('csrf_access_token')
                        }
                    })
                    .then(function (response) {
                        setIV(response.data);
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 401) {
                                console.log('User Logged Out')
                                reset()
                            }
                        }
                        console.log(error);
                    });
                    
                    httpInstance.get('/getRiskFreeRate')
                    .then(function (response) {
                        setRiskFreeRate(response.data);
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 401) {
                                console.log('User Logged Out')
                                reset()
                            }
                        }
                        console.log(error);
                    });

                    if (Object.keys(iv).length !== 0) {
                        // console.log('IV')
                        // console.log(iv)
                        let date = ''
                        if (Object.keys(selectedAtDate).length === 0) {
                            date = selectedExpiry.name
                        }
                        else {
                            date = selectedAtDate.date
                        }

                        httpInstance.post('/getStandardDeviation', {
                            'symbol': selectedUnderlying.symbol,
                            'secType': selectedUnderlying.secType,
                            'date': date,
                            'volatility': iv.value
                        },{
                            headers: {
                                'X-CSRF-TOKEN': getCookie('csrf_access_token')
                            }
                        })
                        .then(function (response) {
                            setStandardDeviationData(response.data);
                        })
                        .catch(function (error) {
                            if (error.response) {
                                if (error.response.status === 401) {
                                    console.log('User Logged Out')
                                    reset()
                                }
                            }
                            console.log(error);
                        });
                    }
                };
            }
        }, 1000);
    
        if (serverStatus.value === 2 && Object.keys(underlyings).length === 0)
        {
            httpInstance.get('/getUnderlyingsList')
            .then(function (response) {
                setUnderlyings(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                console.log(error);
            });
        }

        return () => clearInterval(interval);
    }, [serverStatus, underlyings, selectedUnderlying, basicMarketdData, selectedExpiry, iv, selectedAtDate]);

    const onSelectUnderlying = async (id) => {
        if (serverStatus.value === 2) {
            console.log('onSelectUnderlying')
            setSelectedUnderlying(underlyings[id])
            setOptionsExpiries([])
            setAtDateList([])
            setSelectedExpiry({})
            setSellTrades([])
            setBuyTrades([])
            setIV({})

            httpInstance.post('/getOptionsExpiriesList',{
                'symbol': underlyings[id].symbol
            },{
                headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_access_token')
                }
            })
            .then(function (response) {
                setOptionsExpiries(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                console.log(error);
            });
        }
    }

    const onSelectExpiry = (id) => {
        if (serverStatus.value === 2) {
            console.log('onSelectExpiry')
            setSelectedExpiry(optionsExpiries[id])
            setSellTrades([])
            setBuyTrades([])
            setIV({})
            setAtDateList([])

            httpInstance.post('/getNextDays', {
                'expiry': optionsExpiries[id].name
            },{
                headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_access_token')
                }
            })
            .then(function (response) {
                setAtDateList(response.data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                console.log('onSelectExpiry error : ' + error);
            });
        }
    }
    
    const onFindSellTrade = async () => {
        if (serverStatus.value === 2) {
            console.log('onFindSellTrade')
            setspinnerFindTrade(true)
            
            httpInstance.post("/findOptionsSellingTrade", {
                'symbol': selectedUnderlying.symbol,
                'secType': selectedUnderlying.secType,
                'currency': selectedUnderlying.currency,
                'expiry': selectedExpiry.name,
                'put_risk': putRisk,
                'call_risk': callRisk,
                'max_margin': marginTarget,
                'volatiltiy': volatility
            },{
                headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_access_token')
                }
            })
            .then(function (response) {
                setSellTrades(response.data);
                setspinnerFindTrade(false);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                setspinnerFindTrade(false);
                console.log('onFindSellTrade error : ' + error);
            });
            // fetch(url).then(res => res.json()).then(data => {setSellTrades(data);setspinnerFindTrade(false);});
        }
    }

    const onFindBuyTrade = async () => {
        if (serverStatus.value === 2) {
            console.log('onFindBuyTrade')
            setspinnerFindTrade(true)

            httpInstance.post("/findOptionsBuyingTrade", {
                'symbol': selectedUnderlying.symbol,
                'secType': selectedUnderlying.secType,
                'currency': selectedUnderlying.currency,
                'expiry': selectedExpiry.name,
                'change_type': changeType,
                'perc_change': changePerc,
                'atDate': selectedAtDate.date,
                'volatiltiy': volatility,
                'risk': risk
            },{
                headers: {
                    'X-CSRF-TOKEN': getCookie('csrf_access_token')
                }
            })
            .then(function (response) {
                setBuyTrades(response.data);
                setspinnerFindTrade(false);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        reset()
                    }
                }
                setspinnerFindTrade(false);
                console.log('onFindBuyTrade error : ' + error);
            });
            // fetch(url).then(res => res.json()).then(data => {setBuyTrades(data);setspinnerFindTrade(false);});
        }
    }

    const onLogout = async () => {
        await httpInstance.post("/logout", {}, {
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            console.log('Successfully logging out : ' + response)
            reset()
            navigate("/login");
        })
        .catch(function (error) {
            console.log('Error logging out : ' + error)
            reset()
            navigate("/login");
        });
        
    }


    return (
        <>
            <TopMenu serverStatus={serverStatus} 
                        account={account}
                        onLogout={onLogout}>
            </TopMenu>
            <Routes>
                <Route exact path="/" element={
                    <Navigate to="/login"></Navigate>
                } />
                <Route path="/login" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Login>
                        </Login>
                    ) : (
                        <Navigate to="/positions"></Navigate>
                    )
                } />
                <Route path="/selling" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Navigate to="/login"></Navigate>
                    ) : (
                        <SellOptions bookTradeResponse={bookTradeResponse}
                                serverStatus={serverStatus}
                                underlyings={underlyings}
                                onSelectUnderlying={onSelectUnderlying}
                                selectedUnderlying={selectedUnderlying}
                                basicMarketdData={basicMarketdData}
                                timeState={timeState}
                                optionsExpiries={optionsExpiries}
                                onSelectExpiry={onSelectExpiry}
                                selectedExpiry={selectedExpiry}
                                iv={iv}
                                standardDeviationData={standardDeviationData}
                                riskFreeRate={riskFreeRate}
                                putRisk={putRisk}
                                setPutRisk={setPutRisk}
                                callRisk={callRisk}
                                setCallRisk={setCallRisk}
                                marginTarget={marginTarget}
                                setMarginTarget={setMarginTarget}
                                volatility={volatility}
                                setVolatility={setVolatility}
                                sellTrades={sellTrades}
                                setSellTrades={setSellTrades}
                                onFindSellTrade={onFindSellTrade}
                                spinnerFindTrade={spinnerFindTrade}
                                setBookTradeResponse={setBookTradeResponse}>
                        </SellOptions>
                    )
                } />
                <Route path="/buying" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Navigate to="/login"></Navigate>
                    ) : (
                        <BuyOptions bookTradeResponse={bookTradeResponse}
                                serverStatus={serverStatus}
                                underlyings={underlyings}
                                onSelectUnderlying={onSelectUnderlying}
                                selectedUnderlying={selectedUnderlying}
                                basicMarketdData={basicMarketdData}
                                timeState={timeState}
                                optionsExpiries={optionsExpiries}
                                onSelectExpiry={onSelectExpiry}
                                selectedExpiry={selectedExpiry}
                                iv={iv}
                                standardDeviationData={standardDeviationData}
                                riskFreeRate={riskFreeRate}
                                changeType={changeType}
                                setChangeType={setChangeType}
                                changePerc={changePerc}
                                setChangePerc={setChangePerc}
                                atDateList={atDateList}
                                selectedAtDate={selectedAtDate}
                                setSelectedAtDate={setSelectedAtDate}
                                volatility={volatility}
                                setVolatility={setVolatility}
                                risk={risk}
                                setRisk={setRisk}
                                buyTrades={buyTrades}
                                setBuyTrades={setBuyTrades}
                                onFindBuyTrade={onFindBuyTrade}
                                spinnerFindTrade={spinnerFindTrade}
                                setBookTradeResponse={setBookTradeResponse}>
                        </BuyOptions>
                    )
                } />
                <Route path="/trades" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Navigate to="/login"></Navigate>
                    ) : (
                        <Trades orders={orders}
                            executions={executions}>
                        </Trades>
                    )
                } />
                <Route path="/positions" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Navigate to="/login"></Navigate>
                    ) : (
                        <Positions serverStatus={serverStatus}
                            positions={positions} 
                            updateTradeResponse={updateTradeResponse} 
                            setUpdateTradeResponse={setUpdateTradeResponse}
                            closeTradeResponse={closeTradeResponse}
                            setCloseTradeResponse={setCloseTradeResponse}>
                        </Positions>
                    )
                } />
                <Route path="/volatility" element={
                    Object.keys(UserProfile.getUser()).length === 0 ? (
                        <Navigate to="/login"></Navigate>
                    ) : (
                        <Volatility serverStatus={serverStatus}
                                underlyings={underlyings}
                                onSelectUnderlying={onSelectUnderlying}
                                selectedUnderlying={selectedUnderlying}
                                basicMarketdData={basicMarketdData}
                                timeState={timeState}>
                        </Volatility>
                    )
                } />
            </Routes>
        </>
    )
}

export default App