import companyLogo from './img/logo.png';
import { Container, Nav, Navbar, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import UserProfile from "./userProfile"
import AlertingBadge from './components/AlertingBadge'
import { Currency } from 'react-intl-number-format'


const TopMenu = ( {serverStatus, account, onLogout} ) => {

    return (
        <Navbar expand="lg" sticky="top">
            <Container className="mx-3">
                <Navbar.Brand href="/positions">
                    <img
                        alt=""
                        src={companyLogo}
                        height="80"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                { Object.keys(UserProfile.getUser()).length !== 0 ? (
                    <Nav defaultActiveKey="0">
                        <Nav.Link as={Link} to="/positions">Positions</Nav.Link>
                        <Nav.Link as={Link} to="/selling">Selling</Nav.Link>
                        <Nav.Link as={Link} to="/buying">Buying</Nav.Link>
                        <Nav.Link as={Link} to="/volatility">Volatility</Nav.Link>
                        <Nav.Link as={Link} to="/trades">Trades</Nav.Link>
                        <Nav.Link as={Link} onClick={onLogout}>Logout</Nav.Link>
                    </Nav>
                    ) : ('')
                }

                { serverStatus.value === 0 && Object.keys(UserProfile.getUser()).length !== 0 ? (
                    <AlertingBadge value={serverStatus.text} alertingLevel="danger" unit=""></AlertingBadge>
                    ):('')
                }
            </Container>
            <Container className="mx-3 justify-content-end">
                { Object.keys(account).length !== 0 && Object.keys(UserProfile.getUser()).length !== 0 ? (
                    <Navbar.Text>
                        <Table className="mb-0" size='sm'>
                            <thead>
                                <tr>
                                    <th className='TopMenu-account-table-cell text-center'>Balance</th>
                                    <th className='TopMenu-account-table-cell text-center'>This Month</th>
                                    <th className='TopMenu-account-table-cell text-center'>Last Month</th>
                                    <th className='TopMenu-account-table-cell text-center'>This Year</th>
                                    <th className='TopMenu-account-table-cell text-center'>All Time</th>
                                    <th className='TopMenu-account-table-cell text-center'>Server Status</th>
                                    <th className='TopMenu-account-table-cell text-center'>Account</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        <div style={{"fontSize": "1.1rem"}}>
                                            <Currency>{account.TotalCashValue}</Currency>
                                        </div>
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        {account.perfThisMonth > 0 ? (
                                            <AlertingBadge value={account.perfThisMonth} alertingLevel="success" unit="perc"></AlertingBadge>
                                        ) : (
                                                <AlertingBadge value={account.perfThisMonth} alertingLevel="danger" unit="perc"></AlertingBadge>
                                            )
                                        }  
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        {account.perfLastMonth > 0 ? (
                                            <AlertingBadge value={account.perfLastMonth} alertingLevel="success" unit="perc"></AlertingBadge>
                                        ) : (
                                                <AlertingBadge value={account.perfLastMonth} alertingLevel="danger" unit="perc"></AlertingBadge>
                                            )
                                        }  
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        {account.perfThisYear > 0 ? (
                                            <AlertingBadge value={account.perfThisYear} alertingLevel="success" unit="perc"></AlertingBadge>
                                        ) : (
                                                <AlertingBadge value={account.perfThisYear} alertingLevel="danger" unit="perc"></AlertingBadge>
                                            )
                                        }
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        {account.perfAllTime > 0 ? (
                                            <AlertingBadge value={account.perfAllTime} alertingLevel="success" unit="perc"></AlertingBadge>
                                        ) : (
                                                <AlertingBadge value={account.perfAllTime} alertingLevel="danger" unit="perc"></AlertingBadge>
                                            )
                                        }
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>
                                        {serverStatus.value === 2 ? (
                                            <AlertingBadge value={serverStatus.text} alertingLevel="success" unit=""></AlertingBadge>
                                        ) : ( serverStatus.value === 1 ? (
                                                <AlertingBadge value={serverStatus.text} alertingLevel="warning" unit=""></AlertingBadge>
                                            ) : (
                                                    <AlertingBadge value={serverStatus.text} alertingLevel="danger" unit=""></AlertingBadge>
                                                )
                                            )
                                        }
                                    </td>
                                    <td className='TopMenu-account-table-cell align-middle text-center'>{account.accountName}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Navbar.Text>
                ) : ('')
                }
            </Container>
        </Navbar>
    );
}

export default TopMenu;